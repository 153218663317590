import {
  cancellation_options,
  contract_extension_options,
  payment_method_options,
  switching_date_status
} from "../../../Functions/global";

const contract_object_data = {
  tariff_information: {
    api_call: "offer_result",
    modal: "tariff_information",
    title: "Tarifinformationen",
    border_bottom: true,
    content: [
      {
        title: "Auftrags-ID",
        name: "contract_id",
        frontend_display: true
      },
      {
        title: "Angebots-ID",
        name: "id",
        frontend_display: true
      },
      {
        title: "Gesamtverbrauch",
        name: "display_consumption",
        frontend_display: true
      },
      {
        title: "Anbieter",
        name: "supplier_name",
        frontend_display: true
      }, {
        title: "Tarif",
        name: "name",
        frontend_display: true
      },{
        title: "Vertragstyp",
        name: "sign_type_display",
        frontend_display: true
      },
      {
          title: "Kundentyp",
          name: "customer_type_display",
          frontend_display: true
      },
      {
        title: "Energie",
        name: "display_medium_type",
        api_name: "medium_type",
        frontend_display: true
      },
      {
        title: "Kündigungsfrist",
        name: "cancellation_period_display",
        modal_name: "cancellation_period",
        api_name: "cancellation_period",
        api_sub_structure: ["keyfacts"],
        modal_input: true,
        type: "select",
        validation: { case_name: "select" },
        select_options: cancellation_options,
        frontend_display: true
      },
      {
        title: "Autom. Verlängerung",
        name: "contract_extension_display",
        modal_name: "contract_extension",
        api_name: "contract_extension",
        api_sub_structure: ["keyfacts"],
        modal_input: true,
        type: "select",
        validation: { case_name: "select" },
        select_options: contract_extension_options,
        frontend_display: true
      },
      {
        title: "Mehr / Mindermengentoleranz",
        name: "quantity_tolerance_display",
        modal_name: "quantity_tolerance",
        api_name: "quantity_tolerance",
        api_sub_structure: ["keyfacts"],
        modal_input: true,
        type: "text",
        input_group_text: "%",
        function_name: "getValidPercentage",
        frontend_display: true,
        display_requirements: {
          collapse_index: 1,
          field: ["customer_type"],
          value: ["BUSINESS"]
        },
        display_requirements_frontend: {
          collapse_index: 0,
          field: ["customer_type"],
          value: ["BUSINESS"]
        }
      },
      {
          title: "Preisgarantie",
          name: "price_guarantee_type_display",
          frontend_display: true
      },
      {
          title: "Energiepreis",
          name: "price_rate_full_display",
          modal_name: "price_rate_display",
          api_sub_structure: ["price"],
          api_name: "price_rate",
          modal_input: true,
          type: "text",
          input_group_text: "ct / kWh",
          function_name: "getValidPriceRate",
          frontend_display: true,
          display_requirements: {
              collapse_index: 7777,
              field: ["is_spot", "customer_type", "price_type"],
              value: [false, "BUSINESS", "energy_price"]
          },
          display_requirements_frontend: {
              collapse_index: 0,
              field: ["customer_type", "price_type"],
              value: ["BUSINESS", "energy_price"]
          }
        }, {
          title: "Arbeitspreis",
          name: "contract_price_net_full_display",
          modal_name: "contract_price_net_full_display",
          api_sub_structure: ["price"],
          api_name: "contract_price_net",
          modal_input: true,
          type: "text",
          input_group_text: "ct / kWh",
          function_name: "getValidPriceRate",
          frontend_display: true,
          display_requirements: {
              collapse_index: 8888,
              field: ["is_spot", "customer_type", "price_type"],
              value: [false, "BUSINESS", "contract_price"]
          },
          display_requirements_frontend: {
              collapse_index: 0,
              field: ["customer_type", "price_type"],
              value: ["BUSINESS", "contract_price"]
          }
        },
        {
            title: "Arbeitspreis (Brutto)",
            name: "contract_price_gross_full_display",
            modal_name: "contract_price_gross_display",
            api_sub_structure: ["price"],
            api_name: "contract_price_gross",
            modal_input: true,
            type: "text",
            input_group_text: "ct / kWh",
            function_name: "getValidPriceRate",
            frontend_display: true,
            display_requirements: {
                collapse_index: 88888,
                field: ["is_spot", "customer_type"],
                value: [false, "PRIVATE"]
            },
            display_requirements_frontend: {
                collapse_index: 0,
                field: ["customer_type"],
                value: ["PRIVATE"]
            }
        },


      {
        title: "Grundpreis",
        name: "socket_rate_formated",
        modal_name: "socket_display",
        api_sub_structure: ["price"],
        api_name: "socket_net",
        modal_input: true,
        type: "text",
        max_length: 6,
        input_group_text: "€ / Monat",
        function_name: "getValidBasePrice",
        frontend_display: true,
          display_requirements: {
              collapse_index: 999999,
              field: ["customer_type"],
              value: ["BUSINESS"]
          },
          display_requirements_frontend: {
              collapse_index: 0,
              field: ["customer_type"],
              value: ["BUSINESS"]
          }
      },
        {
        title: "Grundpreis (Brutto)",
        name: "socket_gross_rate_formated",
        modal_name: "socket_gross_display",
        api_sub_structure: ["price"],
        api_name: "socket_gross",
        modal_input: true,
        type: "text",
        max_length: 6,
        input_group_text: "€ / Monat",
        function_name: "getValidBasePrice",
        frontend_display: true,
        display_requirements: {
            collapse_index: 0,
            field: ["customer_type"],
            value: ["PRIVATE"]
        },
        display_requirements_frontend: {
            collapse_index: 22222,
            field: ["customer_type"],
            value: ["PRIVATE"]
        }
      },
      {
        title: "Ablehnungsgrund",
        name: "cancellation_reason_display",
        frontend_display: true,
        modal_input: false,
        display_requirements_frontend: {
          field: ["cancellation_reason"],
          value: ["calculation", "missing_solvency", "bad_solvency", "business_type", "else"]
        }
      },
    ]
  },
  contract_partner: {
    api_call: "customer",
    modal: "contract_partner",
    title: "Vertragspartner",
    border_bottom: true,
    content: [
      {
        title: "Firmen-ID",
        name: "customer_id",
        frontend_display: true
      },
      {
        title: "Firma",
        name: "customer_company",
        api_sub_structure: ["customer_information"],
        api_name: "company",
        modal_input: true,
        frontend_display: true,
        display_requirements_frontend: {
          collapse_index: 0,
          field: ["customer_type"],
          value: ["BUSINESS"]
        },
        display_requirements: {
          collapse_index: 0,
          field: ["customer_type"],
          value: ["BUSINESS"]
        },
        type: "text"
      },
      {
        title: "Kunde",
        name: "private_customer_company_data",
        api_sub_structure: ["customer_information"],
        api_name: "company",
        frontend_display: true,
        display_requirements_frontend: {
          collapse_index: 1,
          field: ["customer_type"],
          value: ["PRIVATE"]
        },
        display_requirements: {
          collapse_index: 1,
          field: ["customer_type"],
          value: ["PRIVATE"]
        },
        type: "text"
      },
      {
        title: "Bonität",
        name: "solvency_score_display",
        api_name: "solvency_score",
        frontend_display: true
      },
      {
        title: "Postleitzahl",
        name: "billing_zip",
        api_name: "zip",
        api_sub_structure: ["customer_information", "billing_address"],
        modal_input: true,
        type: "text",
        max_length: 5,
        frontend_display: true,
        function_name: "getNumericValue"
      },
      {
        title: "Ort",
        name: "billing_city",
        api_sub_structure: ["customer_information", "billing_address"],
        api_name: "city",
        modal_input: true,
        frontend_display: true,
        type: "text"
      },
      {
        title: "Straße",
        name: "billing_street",
        api_sub_structure: ["customer_information", "billing_address"],
        api_name: "line1",
        modal_input: true,
        frontend_display: true,
        type: "text"
      },
      {
        title: "Hausnummer",
        name: "billing_house_number",
        api_name: "line2",
        api_sub_structure: ["customer_information", "billing_address"],
        modal_input: true,
        type: "text",
        frontend_display: true
      }
    ]
  },
  contactPartner: {
    api_call: "customer",
    modal: "contactPartner",
    title: "Ansprechpartner",
    border_bottom: true,
    content: [
      {
        title: "Vorname",
        name: "customer_first_name",
        api_name: "first_name",
        modal_input: true,
        frontend_display: true,
        type: "text"
      },
      {
        title: "Nachname",
        name: "customer_last_name",
        api_name: "last_name",
        modal_input: true,
        frontend_display: true,
        type: "text"
      },
      {
        title: "Telefonnummer",
        name: "customer_phone",
        api_name: "phone",
        modal_input: true,
        frontend_display: true,
        type: "text",
        validation: { case_name: "phone" }
      },
      {
        title: "E-Mail Adresse",
        name: "customer_email",
        api_name: "email",
        modal_input: true,
        frontend_display: true,
        type: "text",
        validation: { case_name: "email" }
      }
    ]
  },
  contractDuration: {
    api_call: "contract",
    title: "Auftrag",
    modal: "contractDuration",
    border_bottom: true,
    content: [
      {
        title: "Belieferungsbeginn",
        name: "contract_begin_display",
        modal_name: "contract_begin",
        api_name: "contract_begin",
        api_function: "handleFormatDate",
        modal_input: true,
        type: "date",
        validation: {
          case_name: "date_compare",
          compare_value: "contract_end"
        },
        frontend_display: true
      },
      {
        title: "Belieferungsende",
        name: "contract_end_display",
        modal_name: "contract_end",
        api_name: "contract_end",
        api_function: "handleFormatDate",
        modal_input: true,
        type: "date",
        frontend_display: true
      },
        {
            title: "Zahlungsart",
            name: "payment_method_display",
            modal_name: "payment_method",
            api_name: "payment_method",
            modal_input: true,
            type: "select",
            select_options: payment_method_options,
            validation: { case_name: "select" },
            frontend_display: true
        },
    ]
  },
  bankData: {
    api_call: "customer",
    title: "Bankkonto",
    modal: "bankData",
    content: [
      {
        title: "Kontoinhaber",
        name: "name_on_account",
        modal_input: true,
        type: "text",
        api_sub_structure: ["bank_account"],
        frontend_display: true,
      },
      {
        title: "IBAN",
        name: "iban",
        modal_input: true,
        api_sub_structure: ["bank_account"],
        type: "text",
        frontend_display: true,
      },
      {
        title: "BIC",
        name: "bic",
        modal_input: true,
        type: "text",
        api_sub_structure: ["bank_account"],
        frontend_display: true,
      },
      {
        title: "Kreditinstitut",
        name: "bank_name",
        modal_input: true,
        api_sub_structure: ["bank_account"],
        type: "text",
        frontend_display: true,
      }
    ]
  },
  upload_agb_file: {
    api_call: "contract",
    modal: "upload_agb_file",
    title: "Allgemeine Geschäftsbedingungen",
    content: [
      {
        title: "Datei",
        file_type: "pdf",
        name: "agb_file_id",
        api_name: "agb_file_id",
        modal_input: true
      }
    ]
  },
  upload_signed_contract: {
    api_call: "document",
    modal: "upload_signed_contract",
    title: "Unterschriebener Energieversorgungsvertrag",
    content: [
      {
        title: "Datei",
        file_type: "pdf",
        name: "signed_contract",
        api_name: "signed_contract",
        modal_input: true
      }
    ]
  },
  upload_authority_file: {
    api_call: "contract",
    modal: "upload_authority_file",
    title: "Maklervollmacht",
    content: [
      {
        title: "Datei",
        file_type: "pdf",
        name: "authority_file",
        api_name: "authority_file",
        modal_input: true
      }
    ]
  },
  consumption_point_status: {
    api_call: "consumption_point_status",
    modal: "consumption_point_status",
    title: "Wechselstatus bearbeiten",
    content: [
      {
        title: "Wechselstatus",
        name: "switching_status",
        api_name: "switching_status",
        modal_input: true,
        type: "select",
        validation: { case_name: "select" },
        select_options: switching_date_status
      },
      {
        title: "Lieferzeitraum bearbeiten",
        name: "edit_begin",
        modal_input: true,
        type: "checkbox",
        default_value: "false",
        checked_value: "true",
        unchecked_value: "false",
        text: "Der bestätigte Lieferbeginn weicht vom Vertrag ab.",
        display_requirements: {
          collapse_index: 0,
          field: ["switching_status"],
          value: ["successful"]
        }
      },
      {
        title: "Bestätigter Lieferbeginn",
        type: "date",
        name: "switching_date",
        api_name: "switching_date",
        default_value: "contract_begin",
        modal_input: true,
        validation: { case_name: "date" },
        display_requirements: {
          collapse_index: 1,
          field: ["edit_begin", "switching_status"],
          value: ["true", "successful"]
        }
      }
    ]
  }
};

export default contract_object_data;
