import React from 'react';
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

class BadgeDot extends React.Component {

    render() {
        const {value, color, is_loaded} = this.props;

        let dot;

        switch (color) {
            case 'green':
                dot = <i className="bg-success"/>
                break;
            case 'orange':
                dot = <i className="bg-eless-orange"/>
                break;
            case 'red':
                dot = dot = <i className="bg-danger"/>
                break;
            default:
                dot = '-/-'
                break;
        }

        return (is_loaded ? <span className="badge badge-dot mr-4">
              {dot} <span className="status">{value}</span>
        </span> : <Skeleton width={160} height={23}/>)
    }
}

BadgeDot.defaultProps = {
    is_loaded: true,
}

BadgeDot.propTypes = {
    color: PropTypes.oneOf(['green', 'orange', 'red', 'gray']),
    text: PropTypes.string,
    is_loaded: PropTypes.bool,
};

export default BadgeDot;