import React from 'react';
import {
    initializeConsumptionPointsForContract,
    initializeSingleContract,
    modify_offer_res,
    modifyContract,
    showErrorMessage,
    validateIban,
    update_history_changes,
    modifyCustomer,
    initializeChronik,
    getDefaultLps, download_load_profile, modifyConsumptionPoint, initConsumptionPoint, createChronik,
    loadAndCheckInsignSession,
    upload_snigned_contract,
    createBlobFromURL,
    createURL,
    createInSignSession,
    uploadInSignDoc, updateTariff,
    downloadDocument,
    redirect,
    updateMultipleConsumptionPointsForContract,
    uploadFile,
    downloadContracts,
    reset_uploaded_file,
    reset_history, download_timeline_file, dynamic_action_call, upload_file_new
} from "../../Index/index";
import {connect} from "react-redux";
import validateVar from "../../Functions/validation/validateVariable";
import isInternetExplorer from "../../Functions/isInternetExplorer";
import {deHashVar} from "../../Functions/handleHashedVariable";
import DynamicInputFieldsModal from "../../Modals/DynamicInputFieldsModal";
import {ConsumptionpointPath, SingleContract} from "../../../globalNavigation";
import isEmptyObject from "../../Functions/isEmptyObject";
import formatPriceForApi from "../../Functions/formatPriceForApi";
import validateNumericAndCommas from "../../Functions/validation/validateNumericAndCommas";
import ChangeableContractData from "../../Displays/Clearing/ChangeableContractData";
import {
    apiError, electricityVoltageLevel, gasVoltageLevel, contract_invalid_status_arr
} from "../../Functions/global";
import consumption_point_data from "./Components/consumption_point_data";
import contract_object_data from "./Components/contract_object_data";
import ContractPageContent from "./Components/ContractPageContent";
import ConsumptionPointPage from "./Components/ConsumptionPointPage";
import isValidDateCustom from "../../Functions/validation/isValidDate";
import handleFormatDate from "../../Functions/handleFormatDate";
import ConsumptionProfileModal from "./Modals/ConsumptionProfileModal";
import SubmitLoadingAnimationModal from "../../LoadingAnimations/SubmitLoadingAnimationModal";
import is_true from "../../Functions/is_true";
const supplier_id = deHashVar(localStorage.getItem("evuSecurityToken"));

//these constant variables render the Title on the page and and display the "name" property from Object for example Contract

class ContractHandler extends React.Component {
    constructor(props) {
        super(props);
        let message_id = props.match.params.message_id;
        let active_tab = ( validateVar(message_id)) ? "1" :"2";


        this.state = {
            activeTab: active_tab,
            message_id: (message_id) ? props.match.params.message_id : null,
            contract: {},
            contract_options: [],
            selected_ids: [],
            history_edited: {},
            is_loaded_consumption_point: false,
            is_loaded_contract: false,
            is_loaded_contract_file: false,
            is_loaded_chronik: false,
            disable_edit_actions: false,
            consumption_points: [],
            consumption_point: {},
            consumption_point_id: null,
            protocol_id: null,
            disable_chat: true,
            selected_csp_ids: [],
            signed_contract_uploaded: false,
            file_uploaded: null,
            reset_consumption_point: false,
            show_loading_modal: false,
            contract_status_submitted: false,
        };
        this.dynamicInputFieldsModal = React.createRef();
        this.consumptionProfileModal = React.createRef();


        if (validateVar(message_id)) {
            let message_id_tmp = this.props.match.params.message_id; // == 'size' and not 'big'

            var url = window.location.toString();
            url = url.replace("/" + message_id_tmp, "");

            window.history.pushState("", "Vertraege", url);
        }

    }

    componentDidMount() {
        let modal_value_object = {};
        let site_name = null;
        let protocol_id = this.props.match.params.protocol_id;
        this.props.onInitialize(this.props.match.params.contract_id); // init contract
        this.handle_loading_modal(true);
        this.set_loading_modal_text("Auftragsdaten werden geladen");
        if(this.props.match.path.includes(SingleContract)) {
            modal_value_object =  contract_object_data;
            site_name = "contract";
            this.props.onInitializeConsumptionPoints(this.props.match.params.contract_id, protocol_id); // init consumption_point
        } else if (this.props.match.path.includes(ConsumptionpointPath)) {
            modal_value_object =  consumption_point_data;
            site_name = "consumption_point";
            this.props.initializeDefaultLoadProfiles();
            let consumption_point_id = this.props.match.params.consumption_point_id;

            this.setState({consumption_point_id: consumption_point_id})
        } else alert("Die Seite konnte nicht geladen werden.");
        this.setState({
            modal_value_object: modal_value_object,
            site_name: site_name,
            protocol_id: protocol_id
        });

        // insign after reload
        const searchObject = new URLSearchParams(window.location.search);
        let in_sign_session_id = searchObject.get('insignsessionid');
        if (validateVar(in_sign_session_id)) {
            this.setState({in_sign_session_id: in_sign_session_id});
            this.props.loadAndCheckInsignSession(in_sign_session_id);
        }
    };

    restart_page_loading = () =>  {
        let {site_name, consumption_point_id, contract, protocol_id} = this.state;
        this.handle_loading_modal(true);
        this.props.reset_history();
        this.setState({
            is_loaded_consumption_point: false,
            is_loaded_contract: false,
            is_loaded_contract_file: false,
            is_loaded_chronik: false,
            reset_consumption_point: false,
        });

        if (site_name === "contract") {
            this.set_loading_modal_text("Auftragsdaten werden geladen");
            this.props.onInitialize(this.props.match.params.contract_id);
            this.props.onInitializeConsumptionPoints(this.props.match.params.contract_id, protocol_id);
        } else if (site_name === "consumption_point") {
            this.set_loading_modal_text("Abnahmestellen werden geladen");
            this.props.onInitialize(this.props.match.params.contract_id);
        }
    };

    handle_api_error_for_changes = (state_name) => {
        let state_val = this.state[state_name];
        if (validateVar(state_val)) {
            this.reset_loading_animation(state_name);
        }
    };

    reset_loading_animation = (state_name) => {
        let is_loaded = this.state['is_loaded_'+state_name];
        if(!is_loaded) this.setState({['is_loaded_'+state_name]: true})
    };


    handle_loading_modal = (status) => {
        const {show_loading_modal} = this.state;
        this.setState({
            show_loading_modal: status
        });
        if(show_loading_modal === true && status === false) document.body.classList.remove('modal-open');
    };


    set_loading_modal_text = (text = null) => {
        this.setState({
            loading_modal_text: text
        });
    };


    componentDidUpdate(prevProps, prevState) {
        const {consumption_points, contract_obj, history, chronik, company, insign_session, blob, upload_file, files, tariff} = this.props;
        let {contract_options, is_loaded, site_name, consumption_point_id, file_uploaded, reset_consumption_point} = this.state;

        if (validateVar(contract_obj) && prevProps.contract_obj !== contract_obj && !is_loaded) {
            let contract = contract_obj;
            if (contract !== apiError){
                // if (validateVar(contract.signed_id)) { // todo warum tut man das? ~ Damit sich der SubmitButton im Modal wieder enabled, wo der Vertrag akzeptiert werden kann
                //     this.setState({
                //         signed_contract_uploaded: true,
                //     });
                // }
                // check if contract is still valid ( if not disabel history changes and set status to revoked)
                if (contract_obj.valid_until_time_stamp < +new Date() && contract_obj.status_original === "submitted") {
                    this.change_contract_status({status: "expired"}, contract_obj);
                } else {
                    let disable_edit_actions = (contract_invalid_status_arr.includes(contract_obj.status)); //disables contract history changes & changing of contract status
                    if (site_name === "consumption_point") {
                        this.set_loading_modal_text("Abnahmestellen werden geladen");
                        // if consumption_point page call it -> needs to be here so we have the customer id
                        let cp_params = {
                            consumption_point_id: consumption_point_id,
                            customer_id: contract.customer_id,
                            protocol_id: contract.protocol_id
                        };
                        this.props.initConsumptionPoint(cp_params);
                    }
                    // call chronik everytime contract updates
                    let chronik_disabled_message = null;
                    if(contract.status === 'customer_checkout')  chronik_disabled_message = 'Der Vertrag muss noch von Kunden angenommen werden bevor die Chronik zugänglich wird.';
                    else if(!validateVar(contract.protocol_id)) chronik_disabled_message = 'Der Kunde konnte den Vertrag nicht rechtzeitig bestätigen.';
                    else this.props.initializeChronik({protocol_id: contract.protocol_id}, site_name, consumption_point_id);
                    this.setState({
                        history_edited: contract.history,
                        protocol_id: contract.protocol_id,
                        disable_edit_actions: disable_edit_actions,
                        chronik_disabled_message: chronik_disabled_message,
                    });
                    if (site_name === "contract") this.handle_loading_modal(false);
                    this.setState({
                        contractName: contract.tariff_name + " Vertrag",
                        is_loaded_contract_file: true,
                        contract: contract,
                        is_loaded_contract: true,
                    });
                    if (contract.status === "confirmed" && contract.customer_type === 'Privat') contract_options.push('revoke');
                    if (contract.status === "submitted") contract_options.push('reject', 'accept');
                    if (!isInternetExplorer()) contract_options.push('export');
                    this.setState({contract_options: contract_options});
                    if (contract.sign_type === 'ONLINE' && this.state.acceptUploadDisabled) this.setState({acceptUploadDisabled: false});
                }
                this.disable_actions();
            } else {
                this.handle_loading_modal(false);
            }
        }

        if (validateVar(consumption_points) && prevProps.consumption_points !== consumption_points) {
            if (upload_file === apiError) {
                this.handle_loading_modal(false);
            } else if (reset_consumption_point === true) this.restart_page_loading(); //disable consumption point table rows if the switching date was updated already
            else {
                if (site_name === "consumption_point") this.handle_loading_modal(false);
                this.setState({
                    history_edited: validateVar(consumption_points.history) ? consumption_points.history : {},
                    consumption_points: consumption_points,
                    consumption_point: (site_name === "consumption_point") ? consumption_points : null,
                    is_loaded_consumption_point: true,
                });
                this.disable_actions();
            }
        }

        //if updating postal code didnt work, end the loading animation and display old state data
        if(consumption_points === apiError) this.handle_api_error_for_changes('consumption_point');

        if (validateVar(history) && prevProps.history !== history) {
            window.location.reload();
            // if (history !== apiError) {
            //     this.restart_page_loading();
            // } else this.handle_loading_modal(false);
        }

        if (validateVar(company) && prevProps.company !== company) {
            if (company !== apiError) {
                this.setState({
                    company: company,
                });
            }
        }

        if (validateVar(chronik) && prevProps.chronik !== chronik) {
            if (chronik !== apiError) {
                this.setState({
                    is_loaded_chronik: true,
                    disable_chat: (contract_obj.status === "customer_checkout"),
                    chronik: chronik
                })
            }
            this.setState({
                disable_chat: (contract_obj.status === "customer_checkout"),
            });
        }

        if (validateVar(upload_file) && prevProps.upload_file !== upload_file) {
            if (upload_file === apiError) {
                this.handle_loading_modal(false);
            }
            else if (is_true(upload_file.contract_upload)) {
                this.setState({
                    signed_contract_uploaded: true,
                    signed_id: upload_file.signed_id,
                });

                this.props.reset_uploaded_file();
            }  // todo reset props
            // this.setState({is_loaded_contract: true});
        }



        if (validateVar(files) && prevProps.files !== files) {
            if (files !== apiError) {
                let params = {
                    [file_uploaded]: files
                };
                if (file_uploaded === "agb_file_id") {
                    this.modify_tariff(params);
                } else if (file_uploaded === "signed_id") {
                    params = {
                        documents: {
                            [file_uploaded]: files
                        }
                    };
                    this.modify_contract(params)
                } else if (file_uploaded === "authority_file") {
                    params.contract_id = contract_obj.id;
                    this.modify_contract(params)
                }
                this.setState({file_uploaded: null}); // reset for next file upload
                // this.restart_page_loading();
            } else {
                this.handle_loading_modal(false);
            }
        }

        if (validateVar(tariff) && prevProps.tariff !== tariff) {
            if (files !== apiError) {
                this.restart_page_loading();
            }else {
                this.handle_loading_modal(false);
            }
        }

        if (validateVar(insign_session) && insign_session !== prevProps.insign_session && validateVar(blob)) {
            if (insign_session !== apiError) {
                this.upload_contract_blob_to_insign(contract_obj, insign_session, blob);
            } else this.handle_loading_modal(false);
        }
    };

    disable_actions = () => {
        //EHUB Only
        //this function disables history changes and switching status changes on initial page load
        //everything is depending on contract status
        const {contract_obj, consumption_points} = this.props;
        const {site_name} = this.state;
        let disabled_csp_rows = [];
        if(validateVar(contract_obj) && validateVar(consumption_points)){
            let disable_edit_actions = (contract_invalid_status_arr.includes(contract_obj.status)); //disables contract history changes & changing of contract status
            let disable_switching_status_btn = disable_edit_actions || contract_obj.status === 'submitted'; //does not make sense to be able to confirm the switching date before the contract is accepted/confirmed
            this.setState({
                disable_edit_actions: disable_edit_actions,
                disable_switching_status_btn: disable_switching_status_btn,
            });
            if (site_name === "contract") { //if switching status button is disabled, disable also selecting of all consumption points in the table
                if (disable_switching_status_btn) for (let csp of consumption_points) disabled_csp_rows.push(csp.id);
                this.setState({disabled_csp_rows: disabled_csp_rows});
            }
        }
    };

    upload_contract_blob_to_insign = (contract_obj, insign_session, blob) => {
        let upload_params = {
            session_id: insign_session,
            send_mail: false,
            call_back_url: window.location.origin + SingleContract + "/" + this.props.match.params.contract_id, //this callbackUrl is used for sendingEmails only
            file_id: contract_obj.contract_file+'',
            customer_id: contract_obj.customer_id+'',
            email: "",
            displayname: 'contract_'+contract_obj.contract_file
        };
        this.props.uploadInSignDoc(upload_params, blob);
    };

    insign_session_create = (params) => {
        this.props.createInSignSession(params);
    };

    modify_contract = (params) => {
        let {contract} = this.state;
        params.customer_id = contract.customer_id;
        params.protocol_id = contract.protocol_id;
        params.contract_id = contract.contract_id;
        params.type = "extended";
        params.options = {with_contract_signed: 1};
        this.set_loading_modal_text("Aktualisiere Daten");
        this.props.onModify(params);
    };

    modify_tariff = (params) => {
        let {contract} = this.state;
        this.set_loading_modal_text("Aktualisiere Daten");
        // params.customer_id = contract.customer_id;
        params.protocol_id = contract.protocol_id;
        params.tariff_id = contract.tariff_id;
        this.props.modify_tariff(params);
    };

    //params contains status & if cancelled also cancellation_reason
    change_contract_status = (params, contract = null) => {
        const {signed_id} = this.state;
        contract = (validateVar(contract)) ? contract : this.state.contract;
        params.customer_id = contract.customer_id;
        params.contract_id = contract.contract_id;
        params.type = "extended";
        params.options = {with_contract_signed: 1};
        if(validateVar(signed_id)){
            params.signed_id = signed_id;
        }
        window.scrollTo(0, 0);
        this.props.onModify(params);
        this.setState({is_loaded_contract: false});
    };

    //the properties are different whether its ELECTICITY or GAS consumption point
    get_consumption_profile_properties() {
        const {consumption_point} = this.state;
        const {default_load_profiles} = this.props;
        let content = consumption_point_data.consumption_profile.content;

        //default profiles
        if(validateVar(default_load_profiles)){
            content[2].select_options = default_load_profiles[consumption_point.medium_type].SLP;
            content[3].select_options = default_load_profiles[consumption_point.medium_type].RLM;
        }

        //add load_profile download row if load_profile uploaded to consumption_point
        if (!consumption_point.is_synthetic) {
            content[4].frontend_display = true; //LoadProfile upload field
            //set Gas / Electricity specification
        }

        let specification_name = 'Spannungsebene';
        let specificattion_options = electricityVoltageLevel;
        if (consumption_point.medium_type === 'GAS') {
            specification_name = 'Druckstufe';
            specificattion_options = gasVoltageLevel;
        }

        content[5].title = specification_name;
        content[5].frontend_display = true;
        content[5].select_options = specificattion_options;

        consumption_point_data.consumption_profile.content = content;

        return consumption_point_data.consumption_profile;
    }

    toggleModal = (modal_name, selected_csp_ids) => {
        //load object with properties for generating input fields by modal_name
        let modal_obj = null;
        let {modal_value_object} = this.state;

        if(modal_name === 'static_consumption_profile') this.consumptionProfileModal.current.toggleModal();
        else{
            if (modal_name === 'consumption_profile') modal_obj = this.get_consumption_profile_properties();
            else if (validateVar(modal_value_object[modal_name])) modal_obj = modal_value_object[modal_name];

            this.setState({selected_csp_ids: selected_csp_ids});
            this.dynamicInputFieldsModal.current.toggleModal(modal_obj);
        }
    };

    object_merge = (a, b) => {
        for(let index_b in b) {
            if (b.hasOwnProperty(index_b)) {
                if (a.hasOwnProperty(index_b)) {
                    a[index_b] = this.object_merge(a[index_b], b[index_b]);
                } else
                    a[index_b] = b[index_b];
            }
        }
        return a;
    };

    upload_file = (name, file) => {
        this.handle_loading_modal(true);
        this.set_loading_modal_text("Lade die Datei hoch");
        let params = {
            name: file.name,
            type: "pdf"
        };
        this.setState({ // set name of file to state for didUpdateFunction after upload
            file_uploaded: name
        });
        this.props.uploadFile(params, file);
    };

    //submit new contract values
    on_submit_clearing = (modal_values, modal) => {
        let {contract, site_name, consumption_point, protocol_id, consumption_point_id} = this.state;
        let compare_obj = null;
        let sub_structure = null;
        let changed_params = {};
        let replace_points_from_string = ["mean_consumption", "consumption"];
        let excluded_inputs_from_params = ["cancellation_display"]; //array of values which won't be sent to the API
        let exception_param_names_cp = (site_name === "contract") ? ["switching_date", "switching_status"] : []; //array of values which won't be sent to the API
        let param_names = Object.keys(modal_values);

        let found_exception = exception_param_names_cp.some(r=> param_names.indexOf(r) >= 0);
        if (site_name === "contract" && !found_exception) {
            compare_obj = contract;
        } else if (site_name === "consumption_point" && !found_exception) {
            compare_obj = consumption_point;
        }
            for (let idx in modal_values) {//check if any of the submitted values actually changed in compare to the original values to prevent unnecessary update request
                if (validateVar(compare_obj)) {
                    if (modal_values.hasOwnProperty(idx) && compare_obj.hasOwnProperty(idx)) {
                        let params_obj = modal_values[idx];
                        if(!excluded_inputs_from_params.includes(idx)){
                            let value = params_obj.value;
                            let api_name = (validateVar(params_obj.api_name)) ? params_obj.api_name : idx;
                            //format price for api and
                            //skip date since .replace is not working on objects
                            if(replace_points_from_string.includes(api_name)) value = value.replace(/\./g, "");
                            if (compare_obj[idx] !== value && !isValidDateCustom(value)) {
                                value = value + '';//convert to string to be able to .replace
                                if (validateNumericAndCommas(value.replace(/,/g, ""))) {
                                    value = formatPriceForApi(value);
                                }
                            }
                            sub_structure = {[api_name]: value};
                            let reversed = false;

                            if (validateVar(params_obj.api_sub_structure)) {
                                let api_sub_structures = params_obj.api_sub_structure;

                                if (!reversed) {
                                    api_sub_structures.reverse();
                                    reversed = true;
                                }
                                for (let sub_arr_name of api_sub_structures) {
                                    let inner = {};
                                    inner[sub_arr_name] = sub_structure;
                                    sub_structure = inner;
                                }
                            }
                            if (compare_obj[idx] !== value) changed_params = this.object_merge(changed_params, sub_structure);
                        }
                    }
                } else {
                    // todo exception case for cps
                    // let {selected_csp_ids} = this.state;
                    // let consumption_points = this.props.consumption_points;
                    // console.log("consumption_points", consumption_points);
                    // if (validateVar(selected_csp_ids) && selected_csp_ids.length > 0 ) {
                    //     for (let cp_id of selected_csp_ids) {
                    //
                    //         let compare_object = consumption_points.find(x => x.id === cp_id);
                    //         let params_obj = modal_values[idx];
                    //         let value = params_obj.value;
                    //         if (compare_obj[idx] !== value) changed_params.idx = value;
                    //     }
                    // }

                    // check if cp data has changed and kick not needed cps
                   break;
                }
            }

            /* TODO Die substructure mit mehreren levels wird immer in unterschiedlicher reihenfolge zusammengebaut
            * um den Fehler zu reproduzieren einfach die invoice_address im consumptionpoint ändern -> akzeptieren -> nochmal ändern
            * */
            if (!isEmptyObject(changed_params) ||  is_true(found_exception)) {
                changed_params.protocol_id = (validateVar(compare_obj) && validateVar(compare_obj.protocol_id)) ? compare_obj.protocol_id : protocol_id;
                changed_params.object_type = modal;
                if (modal === "offer_result") {
                    changed_params.offer_result_id = compare_obj.offer_res_id;
                    this.props.modify_offer_res(changed_params);
                    this.setState({is_loaded_contract: false});
                } else if (modal === "contract") {
                    this.modify_contract(changed_params);
                    this.setState({is_loaded_contract: false});
                }
                else if (modal === "customer") {
                    changed_params.customer_id = compare_obj.customer_id;
                    this.props.modifyCustomer(changed_params); // call request
                    this.setState({is_loaded_contract: false});
                } else if (modal === "consumption_point" || modal === "extended_information" || modal === "invoice") {
                    changed_params.consumption_point_id = consumption_point_id;
                    changed_params.customer_id = compare_obj.customer_id;
                    this.props.modifyConsumptionPoint(changed_params);
                    this.setState({
                        is_loaded_consumption_point: false,
                        reset_consumption_point: true
                        // is_loaded_contract: false
                    });
                } else if(modal === 'consumption_point_status') { //update switching_date & status for consumption point
                    const {selected_csp_ids} = this.state;
                    if(Array.isArray(selected_csp_ids)){ //Update multiple consumptionpoints at once (from contract page)
                        let params = {
                            supplier_id: supplier_id,
                            switching_status: modal_values.switching_status.value,
                            contract_id: contract.contract_id,
                            protocol_id: protocol_id,
                            customer_id: contract.customer_id,
                        };
                        let switching_date = (handleFormatDate(modal_values.switching_date.value)) ? handleFormatDate(modal_values.switching_date.value) : null;
                        if (validateVar(switching_date)) params.switching_date = switching_date;
                        this.setState({
                            selected_csp_ids: [],
                            reset_consumption_point: true
                        });
                        this.props.onModifyMultipleConsumptionPoints(params, selected_csp_ids);
                    }else{ //Update single consumption point (from consumption point page)
                        let params = {
                            supplier_id: supplier_id,
                            consumption_point_id: selected_csp_ids,
                            switching_status: modal_values.switching_status.value,
                            contract_id: contract.contract_id,
                            protocol_id: protocol_id,
                            customer_id: contract.customer_id,
                            switching_date: handleFormatDate(changed_params.switching_date)
                        };

                        this.props.modifyConsumptionPoint(params);
                    }
                } else {
                    alert("Kacper du musst den api call noch eintragen (!!DAS IST ABSTRAKT!!)");
                }
                // close modal after submit
                this.dynamicInputFieldsModal.current.turnOffModal();
                this.consumptionProfileModal.current.turnOffModal();
                this.handle_loading_modal(true);
            }else{
                // close modal if values didn't change but anyways submitted
                this.dynamicInputFieldsModal.current.turnOffModal();
                this.consumptionProfileModal.current.turnOffModal();
            }

    };

    edit_changes = (status, history_id, api_call) => {
        let {site_name} = this.state;

        let function_url = 'data_administration/history/respond/'+history_id;
         let params = {
             status: status,
             object_type: api_call
        };
        if(Array.isArray(history_id)) {
            function_url = 'data_administration/history/respond/0';
            params.history_ids = history_id;
        }

        this.handle_loading_modal(true);
        this.props.update_history_changes(params, function_url);

        if (site_name === "contract") {
            this.setState({is_loaded_contract: false});
        } else if (site_name === "consumption_point") {
            this.setState({is_loaded_consumption_point: false});
        }
        this.setState({disable_edit_actions: true});
        // //accept updated values. Run Update prop, depending on object_name
    };


    create_time_line = (params, file = null) => {
        let {protocol_id} = this.state;
        params.protocol_id = protocol_id;
        this.props.createChronik(params, file);
        this.setState({disable_chat: true})
    };

    upload_signed_contract = (params, file) => {
        this.props.upload_signed_contract(params, file);
        if (params.clearing) this.setState({is_loaded_contract: false})
    };

    download_handler = (name, id) => {//TODO LoadProfile download
        if (name === 'load_profile_id') this.props.download_load_profile({id: id});
    };

    get_display_content = (obj, history_edited, is_loaded) => {
        let {disable_edit_actions, modal_value_object} = this.state;
        let content_arr = [];
        for (let obj_name in modal_value_object)  {

            if (modal_value_object.hasOwnProperty(obj_name)) {
                let obj_tmp = modal_value_object[obj_name];
                    content_arr.push(<ChangeableContractData title={obj_tmp.title}
                                                             properties={obj_tmp.content}
                                                             download={this.download_handler}
                                                             object={obj}
                                                             object_history={history_edited}
                                                             toggle_modal={() => this.toggleModal(obj_tmp.modal, null, null, obj_tmp.api_call)}
                                                             api_call={obj_tmp.api_call}
                                                             edit_changes={(type, history, api_call) => this.edit_changes(type, history, api_call)}
                                                             is_loaded={is_loaded}
                                                             disable_edit_history_btn={disable_edit_actions}
                                                             border_bottom={obj_tmp.border_bottom} />)
            }
        }
        return content_arr;
    };

    render() {
        const {contract, consumption_point, is_loaded_contract,
           history_edited, is_loaded_consumption_point, site_name, show_loading_modal, loading_modal_text, protocol_id} = this.state;
        const {dynamic_action_call, default_file_upload} = this.props;

        let obj = {};
        let is_loaded = false;
        let site_content = null;
        let content = null;
        if (site_name === "contract") {
            obj = contract;
            is_loaded = is_loaded_contract;
            content = this.get_display_content(obj, history_edited, is_loaded);
            site_content = <ContractPageContent
                upload_signed_contract={(params, file) => this.upload_signed_contract(params, file)}
                insign_session_create={(params) => this.insign_session_create(params)}
                create_time_line={(params, file) => this.create_time_line(params, file)}
                {...this.state}
                downloadContract={() => this.props.downloadContract([contract.contract_id])}
                contract_id={this.props.match.params.contract_id}
                content={content}
                toggleModal={this.toggleModal}
                redirect={this.props.redirect}
                site_object={obj}
                upload_file={this.upload_file}
                edit_changes={this.edit_changes}
                timeline_download={this.props.timeline_download}
                change_contract_status={this.change_contract_status}
                dynamic_action_call={dynamic_action_call}
                default_file_upload={default_file_upload}
            />;
        } else if (site_name === "consumption_point") {
            obj = consumption_point;
            is_loaded = is_loaded_consumption_point;
            content = this.get_display_content(obj, history_edited, is_loaded);
            site_content = <ConsumptionPointPage create_time_line={(params) => this.create_time_line(params)}
                                                 {...this.state}
                                                 contract_id={this.props.match.params.contract_id}
                                                 content={content}
                                                 redirect={this.props.redirect}
                                                 toggleModal={this.toggleModal}
                                                 site_object={obj}
                                                 protocol_id={protocol_id}
            />;
        }

        return (<>
            <SubmitLoadingAnimationModal text={loading_modal_text} show={show_loading_modal} />
            <DynamicInputFieldsModal
                ref={this.dynamicInputFieldsModal}
                upload_file={this.upload_file_from_modal}
                onSubmit={(modal_values, api_call) => this.on_submit_clearing(modal_values, api_call)}
                object_data={is_loaded ? {...obj} : {}}
            />
            <ConsumptionProfileModal ref={this.consumptionProfileModal}
                                     onSubmit={(modal_values, api_call) => this.on_submit_clearing(modal_values, api_call)}
                                     object_data={is_loaded ? {...obj} : {}}
                                     default_load_profiles={this.props.default_load_profiles}
                                     is_loaded={is_loaded_consumption_point}
            />
            {site_content}
        </>);
    }
}

let mapStateToProps = function (state) {
    return {
        contract_obj: state.contract_single,
        consumption_points: state.consumption_point,
        iban: state.iban,
        history: state.history,
        chronik: state.chronik,
        default_load_profiles: state.load_profiles,
        company: state.company,
        insign_session: state.insign_session,
        blob: state.blob,
        upload_file: state.upload_file,
        files: state.files,
        tariff: (validateVar(state.tariffs) && validateVar(state.tariffs.tariff)) ? state.tariffs : (validateVar(state.tariff)) ? state.tariff : null , // todo replace in patch
    }
};

let mapDispatchToProps = {
    onInitialize: initializeSingleContract,
    initializeChronik: initializeChronik,
    createChronik: createChronik,
    initConsumptionPoint: initConsumptionPoint,
    onInitializeConsumptionPoints: initializeConsumptionPointsForContract,
    onModifyMultipleConsumptionPoints: updateMultipleConsumptionPointsForContract,
    modifyConsumptionPoint: modifyConsumptionPoint,
    update_history_changes: update_history_changes,
    modifyCustomer: modifyCustomer,
    onModify: modifyContract,
    modify_offer_res: modify_offer_res,
    validateIban: validateIban,
    showErrorMessage: showErrorMessage,
    initializeDefaultLoadProfiles: getDefaultLps,
    download_load_profile: download_load_profile,
    loadAndCheckInsignSession: loadAndCheckInsignSession,
    upload_signed_contract: upload_snigned_contract,
    createBlobFromURL: createBlobFromURL,
    createURL: createURL,
    uploadFile: uploadFile,
    createInSignSession: createInSignSession,
    uploadInSignDoc: uploadInSignDoc,
    downloadDocument: downloadDocument,
    downloadContract: downloadContracts,
    redirect: redirect,
    modify_tariff: updateTariff,
    reset_history: reset_history,
    reset_uploaded_file: reset_uploaded_file,
    timeline_download: download_timeline_file,
    //FileUpload requests
    dynamic_action_call: dynamic_action_call,
    default_file_upload: upload_file_new,
};

let ContractHandlerContainer = connect(mapStateToProps, mapDispatchToProps)(ContractHandler);

export default ContractHandlerContainer;
